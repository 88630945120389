.container {
  display: flex;
  gap: var(--spacing-xl);
  padding: var(--spacing-md) var(--spacing-xl);
  box-shadow: var(--box-shadow);
  border: var(--border-radius);
  background-color: var(--color-secondary);
  min-width: 300px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.content {
  display: grid;
  gap: 1rem;
  width: 100%;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  width: fit-content;
}

.buttonsContainer > button {
  text-transform: uppercase;
  width: fit-content;
}

.isRead {
  background-color: var(--color-light);
}

.warningPointer {
  position: relative;
  top: 1.5rem;
}

@media (max-width: 600px) {
  .container {
    padding: var(--spacing-sm) var(--spacing-md);
    gap: var(--spacing-md);
  }
}
