.secondary {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
}

.filterObjectContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

select {
  width: max-content;
}

.filterObjectContainer > div {
  width: max-content;
}
