.phytosanitaryPage {
  padding: var(--spacing-md);
  min-height: max-content;
}

.title {
  padding-top: 3rem;
  min-width: 300px;
}

.filtersContainer {
  padding-top: 1.5rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.filters {
  display: flex;
  gap: 2rem;
}

.inputSearch {
  width: 100%;
  width: 500px;
}
.inputSelect {
  min-width: 200px;
}

.paginationContainer {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  gap: 1rem;
  align-items: center;
  min-width: fit-content;
}

.paginationText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageButtonContainer {
  min-width: fit-content;
}

@media (max-width: 1200px) {
  .filtersContainer {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .filters {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: none;
  }
  .inputSearch {
    width: 100%;
    min-width: 300px;
  }
  .paginationContainer {
    margin: 0 auto;
    min-width: 250px;
    justify-items: center;
  }
  .phytosanitaryPage {
    width: 100%;
  }
}
