.container {
  display: grid;
  gap: var(--spacing-sm);
  padding: 0 var(--spacing-xl) var(--spacing-xl);
}

.horizontalContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0px;
}

.chartGroup {
  overflow: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.toggleGraphicButton {
  position: absolute;
  right: 3%;
}

.meteoLink {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 80vw;
  margin-top: -15px;
}
.graphGroup {
  padding: 0 105px 0 30px;
}

.graphTitle {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: var(--spacing-sm);
  max-width: var(--table-max-width);
  min-width: var(--table-min-width);
}

.graphTitleGroup {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-sm);
}

.lineColor {
  box-sizing: border-box;
  width: 30px;
  height: 15%;
  margin-top: var(--spacing-xs);
  border-radius: 30px;
}
.lineColor-red {
  background: #ff989b;
}
.lineColor-blue {
  background: #7fbce1;
}
.lineColor-yellow {
  background: #fcdb03;
}
.lineColor-grey {
  background: #b7b6b6;
}
.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 4px 8px;
  max-width: 400px;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  word-wrap: break-word;
  white-space: normal;
  line-break: normal;
  min-height: fit-content;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: white;
}
