.inputGroup {
  display: flex;
  gap: var(--spacing-md);
}

.inputContainer {
  display: flex;
  gap: var(--spacing-sm);
}

.input {
  border: var(--border-input);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  padding: 0.5rem 0 0.5rem 0.5rem;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  background-color: var(--color-secondary);
  color: var(--color-text);
  max-width: 50px;
}
