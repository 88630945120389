.formContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-input);
}

.infoRow,
.plantationInputs,
.locationInputs,
.geographicInputs,
.geodispositionInputs {
  display: grid;
  gap: var(--spacing-md);
  align-items: end;
}
.infoRow {
  grid-template-columns: 1fr 1fr 1fr;
}
.plantationInputs {
  grid-template-columns: 1fr 1fr 1fr;
}
.locationInputs {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.geographicInputs {
  grid-template-columns: 1fr 1fr 1fr;
}
.geodispositionInputs {
  grid-template-columns: 1fr 1fr 1fr;
}
.referenceInputs {
  grid-template-columns: 1fr;
}

.errorMessage {
  color: var(--color-danger);
}

p {
  white-space: normal;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-md);
}

@media (max-width: 800px) {
  .infoRow,
  .plantationInputs,
  .locationInputs,
  .geographicInputs,
  .geodispositionInputs {
    grid-template-columns: 1fr;
  }
  .buttonContainer {
    justify-content: center;
    padding: 0px 0px 0px 7px;
  }
}
