.container {
  display: grid;
  gap: var(--spacing-md);
}
.tagContainer {
  display: flex;
  gap: var(--spacing-md);
}

.tagContainerGrid {
  display: grid;
  min-width: 90px;
  grid-template-columns: repeat(4, minmax(130px, 1fr));
  gap: var(--spacing-md);
}

@media (min-width: 401px) and (max-width: 1200px) {
  .tagContainerGrid {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
  }
}

@media (max-width: 400px) {
  .tagContainerGrid {
    min-width: 60px;
    grid-template-columns: repeat(1, minmax(80px, 1fr));
  }
}