.container {
  position: relative;
  width: 80%;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-right: var(--spacing-lg);
}

.containerSlim {
  width: 30%;
}

.over {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md) 0 0 var(--spacing-xl);
}

.close:hover {
  fill: black;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.buttonContainer > button {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}

button {
  margin-bottom: var(--spacing-xs);
}

.modalContent {
  max-height: 80vh;
  overflow-y: auto;
  margin-bottom: var(--spacing-md);
}

@media (max-width: 600px) {
  .container {
    min-width: 300px;
  }
}
