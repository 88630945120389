.registerPage {
  padding: var(--spacing-md);
  min-height: max-content;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.registerWrapper {
  display: flex;
  justify-content: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  flex: 1;
}

.registerForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: end;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.linkContainer {
  display: flex;
  justify-content: center;
}

.linkContainer:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .registerPage {
    gap: 2rem;
  }

  .formContainer {
    max-width: unset;
  }

  .registerForm {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
