.lineContainer {
  min-width: var(--table-min-width);
  max-width: var(--table-max-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-xs);
  gap: var(--spacing-md);
  border-top: 1px solid var(--color-border);
  background-color: var(--color-dark);
  background-color: var(--color-primary);
  color: var(--color-light);
}

.disclaimerMessage {
  flex-grow: 1;
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  justify-content: center;
}

.closeButton {
  margin-left: auto;
  margin-right: var(--spacing-xs);
}

.closeButton:hover {
  cursor: pointer;
}
