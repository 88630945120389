.container {
  display: grid;
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -ms-border-radius: var(--border-radius);
  -o-border-radius: var(--border-radius);
  overflow: hidden;
  color: var(--color-text);
  width: 100%;
  border: 2px solid var(--color-primary);
  scrollbar-width: 5px;
}
.container::-webkit-scrollbar {
  height: 5px;
}

.head {
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-primary);
  font-weight: 300;
  display: grid;
  align-items: center;
  text-align: left;
}

.headItem,
.expandMore {
  cursor: pointer;
}

.body {
  padding: var(--spacing-xs);
  width: 100%;
}

.row {
  display: grid;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-bottom: 1px solid rgba(0, 123, 196, 0.2);
  align-items: center;
}

.row .column:nth-child(-n + 2) {
  font-weight: 600;
}

.column {
  display: block;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
}

.footer {
  display: inline-flex;
  justify-content: flex-start;
  padding: var(--spacing-md);
  width: fit-content;
}

.pagination {
  display: flex;
  gap: var(--spacing-md);
}

.pagination svg {
  cursor: pointer;
}

.disable {
  opacity: 0;
  pointer-events: none;
}

.columnContainer {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 2.5rem;
  overflow: hidden;
  white-space: nowrap;
}
.columnContainer:hover .tooltip {
  display: initial;
}

.headerColumnContainer {
  display: flex;
  align-items: center;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  padding: 4px 8px;
  max-width: 400px;
  word-wrap: break-word;
  white-space: normal;
  line-break: normal;
  min-height: fit-content;
  transform: translateX(-15%) translateY(-50px);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: white;
  display: none;
}
.dropDown {
  background-color: rgba(255, 255, 255, 0.2);
  padding: var(--spacing-md);
}

@media (max-width: 875px) {
  .row,
  .head {
    padding: var(--spacing-md) var(--spacing-sm);
    gap: var(--spacing-sm);
  }
  .container {
    overflow-x: scroll;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (max-width: 600px) {
  .footer {
    width: 100%;
    justify-content: center;
  }
}
