.lineContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width-risk-small);
  margin-top: var(--spacing-lg);
}

.lineItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  min-width: var(--min-cell-width);
}

.treatmentsTitle {
  position: relative;
}

.itemContainer {
  display: flex;
  justify-content: center;
  gap: 2px;
  min-width: var(--min-cell-width);
}

.verticalItemContainer {
  flex-direction: column;
}

.treatmentContainer {
  padding-top: 2px;
  text-align: center;
}

.hoverIcon {
  cursor: pointer;
}

.addIcon svg path {
  stroke: var(--color-primary);
}

.addIcon {
  cursor: pointer;
}

.addIcon svg:hover path {
  stroke: var(--color-dark);
}

.addIcon svg {
  width: 20px;
  height: 20px;
}

.hoverIcon svg {
  width: 24px;
  height: 24px;
}

.hoverIcon svg {
  fill: var(--color-primary);
}
.hoverIcon svg:hover {
  fill: var(--color-dark);
}

.treatmentDeleteCard {
  bottom: 0;
}

@media (min-width: 1205px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-medium);
    max-width: var(--table-max-width);
  }
}
@media (min-width: 1550px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-large);
    max-width: var(--table-max-width);
  }
}