.formContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.inputFieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-md);
  align-items: end;
}

.formBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-input);
}

.selectRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-md);
  align-items: end;
}

.errorMessage {
  color: var(--color-danger);
}

@media (max-width: 600px) {
  .selectRow {
    grid-template-columns: 1fr;
  }
  .inputFieldsContainer {
    grid-template-columns: 1fr;
  }
}
