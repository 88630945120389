.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-xl);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}
.firstCol {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.firstSectionContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.listElement {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: max-content;
}

.meteoListContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: var(--spacing-md);
}
.meteoListItem {
  display: flex;
  align-items: center;
  width: max-content;
}

.riskTable {
  color: black;
  text-align: center;
  border-collapse: collapse;
}

th {
  border: 1px solid #d7fafa;
  background: #d7fafa;
  padding: 0 1rem;
}

td {
  vertical-align: center;
}

.emptyCell {
  border: none;
  background: none;
}

.tableTitle {
  background: #d7fafa;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tableBodyTitle {
  border: none;
  background: #d7fafa;
  border-top-left-radius: 10px;
}

.iconContainerRiskLevelsLegend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 2.3rem;
}

.iconContainerRiskLevelsLegend > svg {
  padding: 0px 0px 4px 0;
  width: 30px;
  height: 30px;
}

.iconContainer > svg {
  width: 30px;
  height: 30px;
}

/*color circles*/
.tableRiskIcon-HIGH > svg > circle {
  stroke: var(--color-danger);
}
.tableRiskIcon-HIGH stop:nth-child(2) {
  stop-color: var(--color-danger);
}
.tableRiskIcon-MEDIUM > svg > circle {
  stroke: var(--color-medium);
}
.tableRiskIcon-MEDIUM stop:nth-child(2) {
  stop-color: var(--color-medium);
}
.tableRiskIcon-LOW > svg > circle {
  stroke: var(--color-warning);
}
.tableRiskIcon-LOW stop:nth-child(2) {
  stop-color: var(--color-warning);
}

.tableRiskIcon-NONE > svg > circle {
  stroke: var(--color-warning);
}
.tableRiskIcon-NONE stop:nth-child(2) {
  stop-color: var(--color-warning);
}

.plagueDataCell-bordered{
  border: 1px solid var(--color-border);
  width: 50%;
}

.plagueDataCell-observed{
  border-bottom: 4px solid var(--color-observed);
}

.plagueDataCell-HIGH {
  background-color: dimgrey;
}

.plagueDataCell-MEDIUM {
  background-color: darkgrey;
}

.plagueDataCell-NONE {
  background-color: white;
}

@media (max-width: 1200px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .meteoSection {
    margin-top: var(--spacing-lg);
  }
  .meteoListContainer {
    row-gap: var(--spacing-sm);
  }
}

@media (max-width: 600px) {
  .firstSectionContainer {
    grid-template-columns: 1fr;
    font-size: var(--font-size-mobile);
    margin-bottom: 1rem;
  }
  .meteoSection {
    margin-top: var(--spacing-lg);
  }
  .meteoListContainer {
    grid-template-columns: 1fr;
    font-size: var(--font-size-mobile);
    margin-bottom: 1rem;
  }
  td {
    padding: 0;
  }
}
