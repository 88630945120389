.container {
  padding: var(--spacing-md);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-secondary);
  color: var(--color-title);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
}
