.container {
  display: grid;
  gap: var(--spacing-md);
  padding: 0 var(--spacing-md) !important;
}

.inputContainer {
  display: flex;
  gap: var(--spacing-md);
}

@media (max-width: 600px) {
  .inputContainer {
    flex-direction: column;
  }
}
