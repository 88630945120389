.select {
  height: 40px;
  border-radius: var(--border-radius);
  border-right: 0.5rem solid transparent;
  width: 100%;
  max-width: 380px;
  background-color: var(--color-secondary);
  font-family: var(--font-family);
  color: var(--color-text);
}

.secondary {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
}

.initialOption {
  font-style: italic;
}

.verticalPlacementLabel {
  display: flex;
  flex-direction: column;
}

.verticalPlacementLabel select {
  width: 100%;
}

@media (max-width: 600px) {
  .select {
    max-width: unset;
  }
}
