@font-face {
  font-family: "Xunta Sans";
  src: url("./fonts/XuntaSans-Regular.woff2");
}

@font-face {
  font-family: "Xunta Sans Bold";
  src: url("./fonts/XuntaSans-Bold.woff2");
}

:root {
  /* Colores */
  --color-primary: #007bc4;
  --color-secondary: #e4f0f8;
  --color-success: #689f38;
  --color-warning: #fcdb03;
  --color-medium: #ff8000;
  --color-danger: #ff001b;
  --color-info: #17a2b8;
  --color-light: #fff;
  --color-dark: #002947;
  --color-border: #e4f0f8;
  --color-background: #fff;
  --color-background-videoplayercontrols: #3f414a;
  --color-text: #707070;
  --color-title: #36333a;
  --color-observed: #12cf12;

  /* Tipografía */
  --font-family: "Xunta Sans", Arial, sans-serif;
  --font-family-titles: "Xunta Sans", Arial, sans-serif;
  --font-size: 16px;
  --font-size-subtitle: 12px;
  --line-height: 1.5;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-size-little: 0.8rem;
  --font-size-mobile: 20px;

  /* Espaciado */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;

  /* Bordes */
  --border-radius: 1.5rem;
  --border-radius-button: 5rem;
  --border-radius-input: 0.5rem;
  --border-radius-tag: 0.5rem 5rem 5rem 0.5rem;
  --border-radius-timeline: 10px;
  --border-width: 1px;
  --border: var(--border-width) solid var(--color-secondary);
  --border-input: 0;

  /*Breackpoints*/
  --break_points-xs: 0;
  --break_points-sm: 600px;
  --break_points-md: 960px;
  --break_points-lg: 1280px;
  --break_points-xl: 1920px;

  /* Otros */
  --box-shadow: 0px 2px 32px #0000000a;

  --grid-columns-parcel-detail: 81px repeat(15, 1fr) 30px;
  /*> 1200px*/
  /*> 1550px*/
  --grid-columns-parcel-detail-medium: 81px repeat(15, 1fr) 60px;
  --grid-columns-parcel-detail-large: 81px repeat(15, 1fr) 45px;


  --table-min-width: 1120px;
  --graph-min-width: 1000px;

  --table-max-width: 1500px;
  --table-max-width-risk-small: 1100px;
  --graph-max-width: 1350px;

  --min-cell-width: 50px;
}

/* Normalize CSS */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  height: 100vh;
  max-width: 100%;
  /*overflow-x: hidden;*/
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--color-text);
  background-color: var(--color-background);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-title);
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-titles);
}
h1 {
  font-size: 1.75rem;
}
h2 {
  line-height: 1em;
}
p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
label {
  color: var(--color-title);
}
button {
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: var(--line-height);
  cursor: pointer;
}

input,
textarea {
  border: none;
  outline: none;
  line-height: var(--line-height);
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  /**/
  border: var(--border-input);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  padding: 0.5rem 0 0.5rem 0.5rem;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  background-color: var(--color-secondary);
  color: var(--color-text);
}

.disabled {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
  color: var(--color-primary) !important;
}
.disabled:hover {
  cursor: default;
}

select {
  background-color: var(--color-light);
  border: none;
  border-radius: 0px;
  box-shadow: none;
  color: #444;
  font-size: 16px;
  padding: 8px 12px;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

select:focus {
  outline: none;
  box-shadow: 0 0 4px #ccc;
}

select:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}
video {
  aspect-ratio: 16/9;
  background-color: var(--color-dark);
}
.active {
  background-color: var(--color-light) !important;
  color: var(--color-primary) !important;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

section {
  max-width: 100%;
}
section > section {
  padding-left: 0 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: var(--color-secondary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}
