.container {
  display: grid;
  gap: var(--spacing-md);
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: baseline;
  flex-wrap: wrap;
}
.titleH1 {
  margin-right: 1rem;
}
.titleButtonGroup {
  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-columns: repeat(4, auto);
  gap: var(--spacing-md);
}
.titleDescriptionGrid {
  display: grid;
  gap: var(--spacing-md);
}
.headerText {
  color: var(--color-title);
}

.errorMessage {
  color: var(--color-danger);
}

@media (max-width: 600px) {
  .titleDescriptionGrid {
    display: block;
  }
}
