.over {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ventanaFlotante {
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  background-color: white;
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: var(--border-radius);
  border-radius: 25px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.title {
  justify-content: space-between;
}

.content {
  display: grid;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.isRead {
  background-color: var(--color-secondary);
}

.subtitle {
  display: flex;
}

.subtitle h4:first-child::after {
  content: ":\00a0";
}

@media (max-width: 600px) {
  .buttonsContainer {
    font-size: 0.8rem;
  }
}
