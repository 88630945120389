.container {
  display: grid;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  padding-top: 0;
}
.listContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: var(--spacing-md);
}
@media (max-width: 400px) {
  .listContainer {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}
