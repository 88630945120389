.formContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-input);
}

.userInputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-md);
  align-items: end;
}

.errorMessage {
  color: var(--color-danger);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;  
}

@media (max-width: 750px) {
  .userInputs {
    grid-template-columns: 1fr;
  }
  .buttonContainer {
    justify-content: center;
  }
}
