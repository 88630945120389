.lineContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width);
  border-top: 1px solid var(--color-border);
  background-color: var(--color-dark);
  background-color: var(--color-primary);
  color: var(--color-light);
}

.lineItemsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.highlightedItem {
  background-color: var(--color-info);
}

@media (min-width: 1205px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-medium);
    max-width: var(--table-max-width);
  }
}
@media (min-width: 1550px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-large);
  }
}