.container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  z-index: 3;
  height: 112px;
  padding-right: 45px;
}

.title {
  justify-self: start;
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 10px;
  font-size: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 48px;
}

.logoContainer {
  margin-top: -45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: end;
}

.menuButton {
  margin-left: 40px;
}

.actionButtons {
  display: flex;
  gap: var(--spacing-md);
  justify-self: end;
  color: var(--color-light);
  margin-right: 25px;
}

.actionButtonsMobile {
  display: flex;
  gap: var(--spacing-md);
  justify-self: end;
  color: var(--color-light);
  margin-right: 25px;
}

.logoContainer {
  cursor: pointer;
  height: 48px;
  justify-self: end;
}

.logoContainerMobile {
  display: none;
}

.actionButtons > * {
  cursor: pointer;
}

.actionButtonsMobile > * {
  cursor: pointer;
}

.active {
  font-weight: var(--font-weight-bold);
}

@keyframes moverDerecha {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--spacing-md);
  padding: 25px var(--spacing-md);
  position: fixed;
  background-color: #002b4a;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25%;
  z-index: 6;
  animation: moverDerecha 0.6s ease-in-out;
  overflow-y: scroll;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.cover {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.menuItem {
  display: flex;
  background-color: var(--color-secondary);
  color: #002b4a;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  cursor: pointer;
}

.iconMenuItem {
  display: flex;
  justify-content: space-between;
}

@keyframes rotation-up {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotation-down {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.expandedIcon-on {
  animation: rotation-up 0.4s ease-in-out forwards;
}

.expandedIcon-off {
  animation: rotation-down 0.4s ease-in-out forwards;
}

.menuItemLogout {
  padding: var(--spacing-md);
  color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  cursor: pointer;
}

.administrationMenu {
  transform: scaleY(0);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-md);
  animation: escalar 0.4s ease forwards;
}

.administrationMenuItem {
  width: 95%;
  opacity: 0;
  animation: aparecer 0.3s ease-in-out forwards;
  cursor: pointer;
  background-color: rgba(228, 240, 248, 0.7);
}

@keyframes aparecer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes escalar {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}

.administrationMenuItem:nth-child(1) {
  animation-delay: 0.1s;
}
.administrationMenuItem:nth-child(2) {
  animation-delay: 0.3s;
}
.administrationMenuItem:nth-child(3) {
  animation-delay: 0.5s;
}
.administrationMenuItem:nth-child(4) {
  animation-delay: 0.7s;
}

@media (max-width: 768px) {
  .menu {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .container {
    height: 72px;
  }

  .title {
    height: 36px;
    font-size: 40px;
    line-height: 36px;
    margin-top: 6px;
  }

  .logoContainer {
    display: none;
  }

  .menuButton {
    margin-right: 0;
    justify-self: end;
  }

  .logoContainerMobile {
    display: flex;
    justify-content: center;
  }

  .actionButtons {
    display: none;
  }
}
