.container {
  display: flex;
  width: auto;
  justify-content: space-between;
}
.text {
  background-color: var(--color-secondary);
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: 0.5rem 0 0 0.5rem;
  -webkit-border-radius: 0.5rem 0 0 0.5rem;
  -moz-border-radius: 0.5rem 0 0 0.5rem;
  -ms-border-radius: 0.5rem 0 0 0.5rem;
  -o-border-radius: 0.5rem 0 0 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.button {
  border: var(--border);
  border-radius: 0 5rem 5rem 0;
  -webkit-border-radius: 0 5rem 5rem 0;
  -moz-border-radius: 0 5rem 5rem 0;
  -ms-border-radius: 0 5rem 5rem 0;
  -o-border-radius: 0 5rem 5rem 0;
  display: grid;
  padding: var(--spacing-sm);
  place-items: center;
  color: var(--color-secondary);
  cursor: pointer;
}
.button:hover {
  color: var(--color-primary);
}
.maxHeigh {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3rem;
  line-height: 1.4rem;
}
