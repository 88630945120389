.lineContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width);
  border-top: 1px solid var(--color-border);
}

.lineItemsContainer,
.lineItemsContainerWindValue {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  position: relative;
}

.lineItemsContainerWindValue {
  top: 10px;
}

.meteoContainer {
  margin-bottom: 10px;
}

.lineItemsContainer > span:nth-child(2) {
  position: absolute;
  top: 25px;
}
@media (min-width: 1205px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-medium);
    max-width: var(--table-max-width)
  }
}
@media (min-width: 1550px) {
  .lineContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-large);
  }
}

.windValue {
  font-weight: bold;
  color: var(--color-primary);
}
