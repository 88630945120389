.listContainer {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}
@media (max-width: 401px) {
  .listContainer {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

.productCard {
  min-width: 240px;
  row-gap: 0;
}
