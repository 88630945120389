.formGroup {
  border: var(--border-input);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  padding: 0.5rem 1rem;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  background-color: var(--color-secondary);
  color: var(--color-text);
  height: 40px;
}
.icon {
  display: flex;
}
.secondary {
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.disabled {
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
  color: var(--color-primary) !important;
}
.disabled:hover {
  cursor: default;
}

.input {
  border: var(--border-input);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  padding: 0.5rem 0 0.5rem 0.5rem;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  background-color: var(--color-secondary);
  color: var(--color-text);
}
