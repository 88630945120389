.inputGroup {
  border: var(--border-input);
  border-radius: var(--border-radius-input);
  -webkit-border-radius: var(--border-radius-input);
  -moz-border-radius: var(--border-radius-input);
  -ms-border-radius: var(--border-radius-input);
  -o-border-radius: var(--border-radius-input);
  padding: 0.5rem 1rem;
  display: flex;
  gap: var(--spacing-md);
  justify-content: space-between;
  background-color: var(--color-secondary);
  color: var(--color-text);
}

@media (max-width: 600px) {
  .inputGroup {
    flex-direction: column;
  }  
}

.tagControls {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  gap: 17px;
  align-items: center;
}

.button {
  width: 200px;
}

@media (min-width: 601px) and (max-width: 1000px) {
  .button {
    max-width: 100px;
  }
}

@media (max-width: 600px) {
  .tagControls {
    flex-wrap: wrap;
  }
}

.formGroup {
  position: relative;
  display: grid;
  /* gap: var(--spacing-md); */
}

.icon {
  display: flex;
}

.secondary {
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.suggestion {
  display: grid;
  position: absolute;
  width: 100%;
  border: 1px solid #e9e9e9;
  border-top: 0;
  gap: var(--spacing-md);
  top: 86px;
  background-color: #fff;
  z-index: 2;
}

.suggestion span {
  display: flex;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
}
.suggestion span:hover {
  background-color: var(--color-secondary);
}

.sublabel {
  font-size: 0.8rem;
}
