.filterContainer {
  padding-top: var(--spacing-md);
  display: grid;
  gap: var(--spacing-md);
}

.container {
  display: grid;
  gap: var(--spacing-xl);
}

.noParcelsContainer {
  display: flex;
  justify-content: space-between;
}
.buttonClass {
  max-height: 40px;
  margin-right: var(--spacing-md);
}

.paginationContainer {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  gap: 1rem;
  align-items: center;
  min-width: fit-content;
  margin-right: 15px;
  margin-bottom: 10px;
  width: 35%;
  margin-left: auto;
}

.paginationText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageButtonContainer {
  min-width: fit-content;
}