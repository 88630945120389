.tableHeader {
  display: flex;
  justify-content: flex-start;
  min-width: var(--table-min-width);
}
.plagueDataHeader {
  text-align: left;
}
.plagueDataCell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}
.plagueDataCell_base {
  height: 100%;
  width: 100%;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  box-sizing: border-box;
}
.plagueDataCell_observed {
  border-bottom: 5px solid var(--color-observed);  
}
.plagueDataCell-obs{
  border-bottom: 6px, solid var(--color-observed);
}
.plagueDataCell-observable {
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  box-sizing: border-box;
  transition: border-bottom-color 0.3s ease;
}
/*
.plagueDataCell-observed:hover {
  border-bottom-color: rgb(140, 255, 140);
}
.plagueDataCell-observable:hover {
  border-bottom-color: rgb(140, 255, 140);
}*/
.plagueDataCell-HIGH {
  background-color: dimgrey;
}

.plagueDataCell-MEDIUM {
  background-color: darkgrey;
}

.plagueDataCell-NONE {
  background-color: white;
}

.tableRiskGeneralContainer .plagueDataCell:first-of-type {
  justify-content: start;
}

.tableRiskHeader,
.tableRiskContainer {
  display: grid;
  grid-template-columns: var(--grid-columns-parcel-detail);
  text-align: center;
  border-bottom: 2px solid rgb(236, 236, 236);
  min-width: var(--table-min-width);
  max-width: var(--table-max-width-risk-small);
}
.tableRiskContainer {
  min-height: 2.5rem;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 4px 8px;
  margin-bottom: 170px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
}

.tableRiskItemsContainer {
  display: flex;
}

.tableRiskIcon > svg {
  width: 30px;
  height: 30px;
}

.iconContainerRiskLevelsLegend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
}

.iconContainerRiskLevelsLegend > svg {
  padding: 0px 0px 4px 0;
  width: 30px;
  height: 30px;
}

.tableRiskIcon-HIGH > svg > circle {
  stroke: var(--color-danger);
}
.tableRiskIcon-HIGH stop:nth-child(2) {
  stop-color: var(--color-danger);
}
.tableRiskIcon-MEDIUM > svg > circle {
  stroke: var(--color-medium);
  max-height: 1rem;
}
.tableRiskIcon-MEDIUM stop:nth-child(2) {
  stop-color: var(--color-medium);
}
.tableRiskIcon-LOW > svg > circle {
  stroke: var(--color-warning);
}
.tableRiskIcon-LOW stop:nth-child(2) {
  stop-color: var(--color-warning);
}
.tableRiskIcon-NONE > svg > circle {
  stroke: var(--color-warning);
}
.tableRiskIcon-NONE stop:nth-child(2) {
  stop-color: var(--color-warning);
}

@media (max-width: 600px) {
  .tooltip {
    display: none;
  }
}

@media (min-width: 1205px) {
  .tableRiskHeader,
  .tableRiskContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-medium);
    max-width: var(--table-max-width);
  }
}
@media (min-width: 1550px) {
  .tableRiskHeader,
  .tableRiskContainer {
    grid-template-columns: var(--grid-columns-parcel-detail-large);
    max-width: var(--table-max-width);
  }
}