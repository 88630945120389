.lineContainer {
  min-width: var(--table-min-width);
  max-width: var(--table-max-width);
  display: flex;
  justify-content: center;
  padding: var(--spacing-xs);
  gap: var(--spacing-md);
  border-top: 1px solid var(--color-border);
  background-color: var(--color-dark);
  background-color: var(--color-primary);
  color: var(--color-light);
}
