.ol-map {
  min-width: 100%;
  min-height: 300px;
  height: 300px;
  width: 100%;
}
.actionsGroup {
  position: absolute;
  right: 20px;
  top: 5%;
  /*width: 350px;*/
  z-index: 1;
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: space-between;
}
.legendGroup {
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: flex-end;
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 5;
}
.filtersGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 5%;
  z-index: 4;
  bottom: 5%;
}
.buttonLegend {
  width: 150px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0s;
}
.buttonLegend_invisible {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
}
.cardLegend {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
  z-index: 5;
}

.cardLegend_visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0s;
}

.openLayerMap {
  width: 100%;
  height: 400px;
  position: relative;
}

.publicMap {
  width: 100%;
  height: calc(100vh - 196px);
  position: relative;
}

.parcelList {
  padding: var(--spacing-md) 0;
  list-style: none;
}

.modalButtonsContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-md);
}

.modalCloseButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;  
}

@media (max-width: 600px) {
  .actionsGroup {
    max-width: 100%;
  }
  .legendGroup {
    max-width: 100%;
  }
  .cardLegend {
    width: 94%;
  }
}
