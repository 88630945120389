@media (min-width: 1200px) {
  .listContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
  }
}
@media (max-width: 600px) {
  p {
    max-width: 500px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .listContainer {
    min-width: 380px;
  }
}
