.button {
  border-radius: var(--border-radius-button);
  -webkit-border-radius: var(--border-radius-button);
  -moz-border-radius: var(--border-radius-button);
  -ms-border-radius: var(--border-radius-button);
  -o-border-radius: var(--border-radius-button);
  border: 2px solid var(--color-primary);
  padding: 0.5rem 1rem;
  width: 100%;
  color: var(--color-light);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: var(--spacing-sm);
}

span {
  display: flex;
}

.primary {
  background-color: var(--color-primary);
}

.secondary {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.dark {
  background-color: var(--color-dark);
  border-color: var(--color-dark);
  color: var(--color-light);
}

.light {
  background-color: var(--color-light);
  border-color: var(--color-light);
  color: var(--color-dark);
}

.primary_limitWith {
  max-width: 200px;
  background-color: var(--color-primary);
}

.secondary_limitWith {
  max-width: 200px;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.primary_bottomAligned {
  max-width: 200px;
  background-color: var(--color-primary);
  margin-bottom: 0px;
}

.hiddenIcon {
  display: none;
}

.only_icon {
  display: inline-block;
  max-width: min-content;
  padding: 0.5rem;
  line-height: 0.5rem;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-primary);
}

.primary_with_icon {
  background-color: var(--color-primary);
  min-width: 200px;
}

.disabled {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-primary);
}

.disabled:hover {
  cursor: default;
}

@media (max-width: 600px) {
  .hiddenTextMobilButton {
    display: none;
  }
  .hiddenIcon {
    display: flex;
  }
  .primary_with_icon {
    min-width: min-content;
  }
  .primary {
    min-width: fit-content;
  }
  .secondary {
    min-width: fit-content;
  }
}
