.container {
  padding: var(--spacing-md);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -ms-border-radius: var(--border-radius);
  -o-border-radius: var(--border-radius);
  gap: 1rem;
  min-width: 375px;
}
.cardHeader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  min-width: min-content;
  gap: var(--spacing-xs);
}

@media (max-width: 600px) {
  .container {
    max-width: 100%;
  }
  .cardHeader {
    grid-template-columns: 1fr 0.25fr;
  }
}

@media (max-width: 401px) {
  .container {
    min-width: 240px;
  }
}
