.container {
  background-color: var(--color-dark);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -ms-border-radius: var(--border-radius);
  -o-border-radius: var(--border-radius);
  color: var(--color-light);
  display: grid;
  gap: var(--spacing-xl);
}
.header {
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.header h2,
.legendContainer h3 {
  color: var(--color-light);
}
.infoContainer {
  display: flex;
  gap: 1.2rem;
}
.textInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.legendContainer {
  display: grid;
  gap: var(--spacing-md);
}
.itemContainer,
.item {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
  align-items: center;
  flex-wrap: wrap;
}
.buttonCloseLegend {
  justify-self: end;
}

.subtitle {
  font-size: var(--font-size-little);
}
