.pageContainer {
  height: 100%;
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.buttonContainer {
  display: inline-flex;
  position: relative;
  margin-top: -4rem;
  justify-content: flex-end;
  width: fit-content;
  align-self: flex-end;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.modalContent {
  padding: var(--spacing-lg);
}

@media (max-width: 600px) {
  .actionButtons {
    gap: var(--spacing-xs);
  }
  .modalContent {
    padding: var(--spacing-md);
  }
  .actionButtons > button {
    padding: var(--spacing-sm);
  }
  .buttonContainer {
    margin-top: 0;
    justify-content: center;
    width: 100%;
  }
}
