.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-md);
}

.errorMessage {
  color: var(--color-danger);
}

.highlightedText {
  font-weight: bold !important;
  color: var(--color-info) !important;
}

.buttonLimit {
  max-width: 200px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.buttonContainer > button {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
.inputLimit {
  max-width: 400px !important;
}
