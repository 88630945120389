.container {
  display: grid;
  /*gap: var(--spacing-md);*/
  padding: 0 var(--spacing-xs) 0;
}
.filterPlagueContainer {
  display: grid;
  /*align-items: center;*/
  grid-template-columns: repeat(6, 1fr);
  gap: var(--spacing-md);
}
.switchContainer {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: var(--spacing-md);
}

@media (max-width: 600px) {
  .filterPlagueContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    /*align-items: baseline;*/
  }
}
